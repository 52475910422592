// load sass
import '../sass/common.scss'; //scssの読み込み
// modernizr // webp
import {modernizer}  from "./common/modernizr.js";
modernizer();
// load imageSwitch
import {imageSwitch} from "./common/sub_imageswtch.js";
imageSwitch();

//HAMBURGER BUTTON.
// INIT
const hamburgerButton = document.getElementById('hamburger-button');
const closeButton = document.getElementById('smart-close');
const foundationMat = document.getElementById('smart-nav');
const animateContent = document.getElementById('smart-nav-content');
// FUNCTION.
let switchHamburger = () => {
    if (!foundationMat.classList.contains('standby')) {
        foundationMat.classList.add('standby');
        setTimeout(() => {foundationMat.classList.add('active');},250);
        setTimeout(() => {animateContent.classList.add('active');},500);
    } else {
        animateContent.classList.remove('active');
        setTimeout(() => {foundationMat.classList.remove('active');},500);
        setTimeout(() => {
            foundationMat.classList.remove('standby');
        },1000);
    }
};

// HEADER MINIMUM.
const currentWindowHeight = window.innerHeight;
let commonRenderSync = false;
let commonScrollEvent = () => {
    if (!commonRenderSync) {
        commonRenderSync = false;

        let currentPosition = document.body.scrollTop;
        if ((currentWindowHeight / 1.5) <= currentPosition) {
            document.getElementById('header').classList.add('active');
        } else {
            document.getElementById('header').classList.remove('active');
        }
    }
};
// END HEADER MINIMUM.

// loading after screen view.
let loadComplete = () => {
    window.scrollTo(0,0)
    document.getElementById('corporate').classList.add('ready')
};
// END FUNCTION.
// ONCLICK.
hamburgerButton.onclick = () => {switchHamburger();};
closeButton.onclick = () => {switchHamburger();};
//END HAMBURGER BUTTON.

// SMOOTH SCROLL
let headH = document.getElementById('header').offsetHeight;
document.body.style.marginTop = headH + "px";
let smoothScroll = (target, offset) => {
    let toY;
    let nowY = window.pageYOffset;      // current scroll value
    const divisor = 8;
    const range = (divisor / 2) + 1;

    //ターゲットの座標
    const targetRect = target.getBoundingClientRect();
    const targetY = targetRect.top + nowY - offset;
    //スクロール終了まで繰り返す処理
    (function () {
      let thisFunc = arguments.callee;
      toY = nowY + Math.round((targetY - nowY) / divisor);
      window.scrollTo(0, toY);
      nowY = toY;

      if (document.body.clientHeight - window.innerHeight < toY) {
        //最下部にスクロールしても対象まで届かない場合は下限までスクロールして強制終了
        window.scrollTo(0, document.body.clientHeight);
        return;
      }
      if (toY >= targetY + range || toY <= targetY - range) {
        window.setTimeout(thisFunc, 10);
      } else {
        window.scrollTo(0, targetY);
      }
    })();
  };

const smoothOffset = headH;
const links = document.querySelectorAll('a[href*="#"]');
for (let i = 0; i < links.length; i++) {
  links[i].addEventListener('click', function (e) {

    const href = e.currentTarget.getAttribute('href');
    const splitHref = href.split('#');
    const targetID = splitHref[1];
    const target = document.getElementById(targetID);

    if (target) {
      smoothScroll(target, smoothOffset);
    } else {
      return true;
    }
    return false;
  });
}
// END SMOOTH SCROLL

//
window.addEventListener('load' ,function() {
    loadComplete();
    document.addEventListener('scroll', commonScrollEvent, {passive: true});
});
